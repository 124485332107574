var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-page" }, [
    _c("div", { staticClass: "main-wrapper" }, [
      _c(
        "div",
        { staticClass: "error-box" },
        [
          _c("h1", [_vm._v("404")]),
          _vm._m(0),
          _c("p", [_vm._v("The page you requested was not found.")]),
          _c(
            "router-link",
            { staticClass: "btn btn-custom", attrs: { to: "/" } },
            [_vm._v("Back to Home")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("i", { staticClass: "fa fa-warning" }),
      _vm._v(" Oops! Page not found!")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }